<template>
  <div v-frag class='d-flex'>
    <div v-if='isContentInPages' v-frag>
      <v-row>
        <v-col
          v-for='page in pages'
          :key='page.id'
          :class="{
          'col-lg-6': page.content.length === 1,
        }"
          class='pa-0'
          cols='12'
        >
          <v-card class='rounded-lg elevation-3 ma-5 pa-5'>
            <v-card-title class='font-weight-bold headline pa-0 mb-8'>{{ userRole }}/{{ page.pageName }}</v-card-title>
            <v-row>
              <v-col
                v-for='section in page.content'
                :key='section.id'
                :class="{
                  'col-lg-12': page.content.length === 1,
                  'column': page.content.length !== 1
                }"
                class='pa-0 px-11'
                cols='12'
                lg='6'
                xs='12'
              >
                <v-form
                  ref='sectionTitle'
                  lazy-validation
                >
                  <page-section-content
                    :activeEdit='onActiveEdit'
                    :focused='focused'
                    :inputEditId='inputEditId'
                    :is-general-settings='false'
                    :section='section'
                    @activeChanged='onActiveEdit'
                    @changeEditId='onEditIdChange'
                    @focusChanged='onFocusChanged'
                    @saveTitle='saveTitle'
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div class='mx-5 mb-5 d-flex justify-end'>
        <v-btn
          :loading='loadingSave'
          class='primary white--text rounded-sm'
          min-width='250'
          @click='onSave'
        >
          Save
        </v-btn>
      </div>
    </div>
    <v-card-title
      v-else
      class='headline justify-center'
    >
      There are no content in {{ userRole }} at that moment, please wait :c
    </v-card-title>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import PageSectionContent from '../components/PageSectionContent'
import userOperations from '../services/userOperations'

export default {
  name: 'EditPage',
  components: {PageSectionContent},
  data: () => ({
    sectionsData: [],
    loadingSave: false,
    inputEditId: -1,
    focused: false,
    activeEdit: false
  }),
  computed: {
    ...mapState('cmsStore', ['selectedRoleId']),
    ...mapGetters('cmsStore', ['getContentByRole']),
    isContentInPages() {
      return this.pages.every(page => !!page.content.length)
    },
    userRole() {
      const titleFromParams = this.$route.params?.pageName.split('-')
        .join(' ')
      return titleFromParams.charAt(0)
        .toUpperCase() + titleFromParams.slice(1)
    },
    pages() {
      return this.getContentByRole(this.selectedRoleId)
    }
  },
  methods: {
    ...mapActions('cmsStore', ['sendSectionData', 'getCMSContent']),
    onActiveEdit(value) {
      this.activeEdit = value
    },
    onFocusChanged(value) {
      this.focused = value
    },
    saveTitle(content, sectionId) {
      if (content) {
        this.sectionsData.push({
          sectionId,
          content
        })
      }
    },
    onEditIdChange(sectionId) {
      this.inputEditId = sectionId
    },
    async onSave() {
      for (const key in this.$refs.sectionTitle) {
        this.$refs.sectionTitle[key].validate()
      }
      const inputs = document.querySelectorAll('input')
      const isValid = Array.from(inputs)
        .every(el => el.value !== '')
      if (isValid) {
        this.loadingSave = true
        await this.sendSectionData(this.sectionsData)
        await this.getCMSContent()
        const operations = await userOperations.getOperations()
        await this.$store.dispatch('operations', operations)
        this.sectionsData = []
        this.loadingSave = false
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.row {
  margin: 0;
}

@media (min-width: 1264px) {
  .column:nth-child(odd) {
    padding-right: 40px !important;
  }
}
</style>